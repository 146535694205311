import React from "react"
import {Link} from "gatsby"
import "./TagList.scss"

const TagList = ({
  items,
  headline
}) => {

  const tags = []

  // eslint-disable-next-line array-callback-return
  items.map((item) => {
    item.node.categories_names
      .filter(itemAttribute => itemAttribute.taxonomy === "project_tags")
      // eslint-disable-next-line array-callback-return
      .map(itemTaxonomy => {
        tags.push(itemTaxonomy.name.toLowerCase())
      })
  })

  const sortedUniqueTags = Array.from(new Set(tags)).sort()

  return (
    <div className="section section-tag-list container-fluid pt-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="bracket-text mb-2">{headline}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 item-column">
            {
              sortedUniqueTags.map(tag => {
                return (
                  <div className="tag-list-item" key={tag}>
                    <h2 className="bracket-text tag-headline text-md" id={tag}>{tag}</h2>
                    <ul>
                      {
                        items
                          // eslint-disable-next-line
                          .map(item => {
                            if (item.node.categories_names.filter(itemAttribute => itemAttribute.taxonomy === "project_tags").filter(e => e.name.toLowerCase() === tag).length) {
                              return (
                                <li key={item.node.slug}>
                                  <Link
                                    to={`/projects/${item.node.slug}`}
                                    className="d-block text-md text-link"
                                  >
                                    {item.node.title}
                                  </Link>
                                </li>
                              )
                            }
                          })
                      }
                    </ul>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default TagList
